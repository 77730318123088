<template>
  <div id="labelDetail" v-wechat-title='titleName'>
    <div class="title"><svg-icon icon-class="hashtag" />{{ title }}</div>
    <StandardList :dataList="dataList"></StandardList>
    <CommonPagination
      :pageNum="pageNum"
      :pageSize="pageSize"
      :totalNum="totalNum"
      placement="right"></CommonPagination>
  </div>
</template>

<script>
import StandardList from './components/StandardList.vue'
import CommonPagination from '@/components/CommonPagination.vue'
export default {
  components: {
    StandardList,
    CommonPagination
  },
  data() {
    return {
      dataList: [],
      pageNum: 1,
      pageSize: 15,
      totalNum: 0,
      title: this.$route.params.id,
      titleName: this.$route.params.id + ' - 广东省碳达峰碳中和标准化技术信息公共服务平台' 
    }
  },
  mounted() {
    this.showPage();
  },
  methods: {
    showPage() {
      this.$API.StandardList({
        standardLabel: this.$route.params.id,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }).then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.totalNum = res.data.data.total;
          window.scrollTo(0, 0)
        }
      })
    }
  }
}
</script>

<style lang='scss'>
  #labelDetail {
    width: 900px;
    margin: 0 auto;


    .title {
      padding: 30px 0 40px;
      line-height: 46px;
      font-size: 32px;
      font-weight: 600;
      color: #303133;
      text-align: center;
      margin: 0 10px;
      border-bottom: 1px solid #dcdfe6;;

      .svg-icon {
        width: 28px;
        height: 28px;
        color: #05A081;
        margin-right: 5px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
      }
    }
  }
</style>